import React, { Component } from "react";
import MainPage from "./Components/main-page";
class App extends Component {
  render() {
    return (
      <div className="App">
        <MainPage />
      </div>
    );
  }
}

export default App;
